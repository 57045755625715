import axios from 'axios';

export const PostRequestAPI = async (apiurl, payload, access_token, contentType) => {
    const headers = {
      'Accept':"application/json",
    }
    if(access_token){
      headers.Authorization = `Bearer ${access_token}`
    }
    if(contentType){
      headers['Content-Type']='multipart/form-data'
    }
    const getResponse = axios.post(`${process.env.REACT_APP_API_URL}api/${apiurl}`, payload, {
      headers: headers,
    }).then(function (result) {
      return result;
    }).catch((e) => e.response);
    return getResponse;
};
