import React from "react";
import { Routes, Route, Navigate } from "react-router-dom"; // Import Routes and Route directly
import "bootstrap/dist/css/bootstrap.min.css";
import SignUp from "./layouts/SignUp";
import App_url from "./components/common/Constant";
import SignIn from "./layouts/SignIn";
import Home from "./layouts/Home";
import Detail from "./layouts/Detail";
import Context from "./context/context";
import { useSelector } from "react-redux";
import History from "./layouts/History";
import HistoryDetail from "./layouts/HIstoryDetail";
const App = () => {
  const { accessToken, successModalData,productDetail } = useSelector((state) => state.adminReducers);
  const RoutUrlSet = () => {
    if (!accessToken) {
      return "/signin";
    }
    return "/home";
  };
  return (
    <Context accessToken={accessToken} successModalData={successModalData} productDetail={productDetail} >
      <Routes>
        <Route path="*" element={<Navigate replace to={RoutUrlSet()} />} />
        <Route
          path={App_url?.link?.signIn}
          element={
            !accessToken ? <SignIn /> : <Navigate replace to={RoutUrlSet()} />
          }
        />
        <Route
          path={App_url?.link?.signUp}
          element={
            !accessToken ? <SignUp /> : <Navigate replace to={RoutUrlSet()} />
          }
        />
        <Route
          path={`${App_url?.link?.detail}/:id`}
          element={
            accessToken ? <Detail /> : <Navigate replace to={RoutUrlSet()} />
          }
        />
        <Route
          path={App_url?.link?.home}
          element={
            accessToken ? <Home /> : <Navigate replace to={RoutUrlSet()} />
          }
        />
        <Route
          path={App_url?.link?.history}
          element={
            accessToken ? <History /> : <Navigate replace to={RoutUrlSet()} />
          }
        />
        <Route
          path={`${App_url?.link?.historyDetail}/:id`}
          element={
            accessToken ? (
              <HistoryDetail />
            ) : (
              <Navigate replace to={RoutUrlSet()} />
            )
          }
        />
      </Routes>
    </Context>
  );
};

export default App;
