/* eslint-disable */
import { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Ws_OnMessage } from "./onMessage";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const SocketConnect = createContext(null);

function Context({ children, accessToken, successModalData, productDetail }) {
  const [connection, setConnect] = useState(null);
  const dispatch = useDispatch();
const navigate = useNavigate()
  useEffect(() => {
    let socket = null;

    const handleWebSocketMessage = (event) => {
      dispatch(Ws_OnMessage({ evt: event, ws: socket, successModalData: successModalData,navigate,connection,accessToken,productDetail }));
    };

    const connectWebSocket = () => ((dispatch, getState)=>{
      const {accessToken} = getState()?.adminReducers;
      if (accessToken && !connection) {
        socket = new WebSocket(
          `wss://${process.env.REACT_APP_API_DOMAIN}/${accessToken}/`
        );

        socket.addEventListener("open", () => {
          console.log("WebSocket connection established.");
        });
        socket.addEventListener("message", handleWebSocketMessage);
        socket.addEventListener("error", (error) => {
          console.error("WebSocket error:", error);
        });
        socket.addEventListener("close", () => {
          console.log("WebSocket connection closed. Reconnecting...");
          setConnect(null)
          setTimeout(dispatch(connectWebSocket()), 3000); // Attempt to reconnect after 3 seconds
        });
        setConnect(socket);
      }
    })

    dispatch(connectWebSocket()) // Initial connection

    return () => {
      if (socket) {
        socket.removeEventListener("message", handleWebSocketMessage);
        socket.close();
        console.log("WebSocket connection closed.");
      }
    };
  }, [accessToken, dispatch, successModalData]);

  return (
    <SocketConnect.Provider value={{ connect: connection }}>
      {children}
      <ToastContainer />
    </SocketConnect.Provider>
  );
}

export default Context;
