import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactButton from "react-bootstrap/Button";
import { useNavigate } from 'react-router-dom';

export default function Button(props) {
  const [loader, setLoader] = useState(false);
  const router = useNavigate()

  const onClick =async (e) =>{
      e.preventDefault();
      if(props?.to){
        router(props?.to)
      }else{
          setLoader(true);
          await props?.onClick(e);
          setLoader(false);
      }
  }

  return (
    <ReactButton onClick={onClick} disabled={loader} type={props?.type} variant={props?.variant} className={`btn button ${props?.className}  btn-${props?.size}`}>
      {props?.children}
    </ReactButton>
  )
}
Button.propTypes = {
    variant: PropTypes?.any,
    className: PropTypes?.any,
    type: PropTypes?.any,
    onClick: PropTypes?.func,
    size: PropTypes?.any,
}
Button.defaultProps = {
    variant: "",
    className: "",
    size: "",
    onClick: ()=>{},
    type: "button"
}