import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setShowSuccessModal } from '../../../redux/actions/adminActions';

export default function LogOutModal({ handleClose = () => {}, show = false }) {
    const dispatch = useDispatch();
    const { successModalData } = useSelector((state) => state.adminReducers);

    const handleCloseModal = async () => {
        if (successModalData?.data?.callBackModal) {
            await successModalData?.data?.callBackModal();
        }
        handleClose();
    };

    const CloseData = () => {
        dispatch(setShowSuccessModal({ show: '' }));
    };

    if (successModalData?.show !== 'Log_Out') {
        return null;
    }

    return (
        <Modal show={true} onHide={CloseData} animation={false} centered size="sm">
            <Modal.Body className="text-center p-4">
                <h6 className="logout-modal-title">Log Out</h6>
                <h6 className="logout-modal-subtitle">Are you sure you want to log out?</h6>
                <div className="d-flex align-items-center">
                    <button className="me-2 w-100 btn mt-2 bg-secondary text-white" size="sm" variant="dark" onClick={CloseData}>Cancel</button>
                    <button className="ms-2 w-100 btn mt-2 bg-danger text-white" size="sm" variant="primary" onClick={handleCloseModal}>Logout</button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

LogOutModal.propTypes = {
    handleClose: PropTypes.func,
    show: PropTypes.bool,
};
