import { toast } from "react-toastify";

/* eslint-disable eqeqeq */
export function uuidv4() { // Public Domain/MIT
  let d = new Date().getTime(); // Timestamp
  let d2 = (
      (typeof performance !== 'undefined') && 
      performance.now && 
      (performance.now() * 1000)
  ) || 0; // Time in microseconds since page-load or 0 if unsupported
  
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      let r = Math.random() * 16; // random number between 0 and 16
      if (d > 0) { // Use timestamp until depleted
          r = ((d + r) % 16) | 0;
          d = Math.floor(d / 16);
      } else { // Use microseconds since page-load if supported
          r = ((d2 + r) % 16) | 0;
          d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
  });
}


export function getCurrentURL(context) {
    const { req } = context;

    const host = req?.headers?.host;
    const protocol = req?.headers['x-forwarded-proto'] || 'http';
    return `${protocol}://${host}`;
}
function checkEmail(emailAddress) {
    return String(emailAddress)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}
function ValidateEmail(value, error1, error2) {
    const emailRegex = checkEmail(value)
    if (emailRegex) {
      return false;
    } else {
      if( value == ""){
        return error1 || "Enter email address";
      }
      return error2 || 'Invalid email address!';
    }
}
const checkPasswordValidity = (value) => {
    const isNonWhiteSpace = /^\S*$/;
    const isContainsUppercase = /^(?=.*[A-Z]).*$/;
    const isContainsLowercase = /^(?=.*[a-z]).*$/;
    if (!isContainsUppercase.test(value) && !isContainsUppercase.test(value) && (!isContainsLowercase.test(value)) ) {
      return "Password must have at least one uppercase character, one lowercase character, and one digit.";
    }
    if (!isNonWhiteSpace.test(value)) {
      return "Password must not contain Whitespaces.";
    }
  
    if (!isContainsUppercase.test(value)) {
      return "Password must have at least one Uppercase Character.";
    }
  
    if (!isContainsLowercase.test(value)) {
      return "Password must have at least one Lowercase Character.";
    }
  
    const isContainsNumber = /^(?=.*[0-9]).*$/;
    if (!isContainsNumber.test(value)) {
      return "Password must contain at least one Digit.";
    }
  
    // const isContainsSymbol =
    //   /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
    // if (!isContainsSymbol.test(value)) {
    //   return "Password must contain at least one Special Symbol.";
    // }
  
    const isValidLength = /^.{8,16}$/;
    if (!isValidLength.test(value)) {
      return "Password must be 8-16 Characters Long.";
    }
  
    return null;
}
export const AuthenticateResponse = (response, state)=> {
    if(response?.status == 400){
      if(typeof(response?.data?.error) == "string"){
        toast.error(response?.data?.error);
      }
      if(typeof(response?.msg) == "string"){
        toast.error(response?.msg);
      }
    }else if(response?.status == 403){
      if(typeof(response?.data?.error) == "string"){
        toast.error(response?.data?.error);
        if(state.redirect){
          return state.redirect();
        }
      }
    }
}
const utils = {
    uuidv4:uuidv4,
    getCurrentURL:getCurrentURL,
    ValidateEmail:ValidateEmail,
    checkPasswordValidity:checkPasswordValidity,
    AuthenticateResponse:AuthenticateResponse,

}

export default utils;