import { toast } from "react-toastify";
import {
  ViewProductDetails,
  getAccessToken,
  getProductList,
  setLoader,
  setRefreshBooking,
  setShowSuccessModal,
  setUpdateList,
} from "../redux/actions/adminActions";

// In your onMessage.js file
export const Ws_OnMessage = ({ evt, ws, accessToken }) => {
  return (dispatch) => {
    const ws_onmessage = JSON.parse(evt.data);
    if (
      ws_onmessage?.status === 401 &&
      ws_onmessage?.message === "unauthorized access" &&
      ws?.url.includes(accessToken)
    ) {
      toast.error("Your session has ended, please log in.");
      dispatch(getAccessToken(""));
    }
    const URL = ws_onmessage?.url;
    dispatch(setLoader(false));
    switch (URL) {
      case "get_all_webinar":
        if (ws_onmessage?.status === 200) {
          dispatch(getProductList(ws_onmessage));
        } else {
          dispatch(getProductList(""));
        }
        break;
      case "create_webinar":
        if (ws_onmessage?.status === 200) {
          dispatch(setUpdateList(new Date()));
        }
        break;
      case "update_webinar_status":
        if (ws_onmessage?.status === 200) {
          dispatch(setUpdateList(new Date()));
        }
        break;
      case "update_booking":
        if (
          ws_onmessage?.status === 200 &&
          ws_onmessage?.data?.request?.price
        ) {
          dispatch(
            setShowSuccessModal({
              show: "BOOKINGSUCCESS",
              data: ws_onmessage?.data?.request?.price,
              msg: ws_onmessage?.data?.request
            })
          );
          toast.success(ws_onmessage?.message);
        }
        break;

      case "get_single_webinar":
        if (ws_onmessage?.status === 200) {
          dispatch(ViewProductDetails(ws_onmessage));
        } else {
          dispatch(ViewProductDetails(""));
        }
        break;
      case "refresh_booking":
        if (
          ws_onmessage?.status === 200 && 
          window.location.href.includes("webinar")
        ) {
          dispatch(setRefreshBooking(ws_onmessage));
        }
        break;

      default:
        break;
    }
  };
};
