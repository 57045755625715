import React, { useContext, useEffect } from "react";
import App_url from "../components/common/Constant";
import ProductCard from "../components/common/ProductCard";
import { useNavigate } from "react-router-dom";
import { SocketConnect } from "../context/context";
import wsSend_request from "../components/common/WsSendRequest";
import { useDispatch, useSelector } from "react-redux";
import { ViewProductDetails, getAccessToken, setLoader, setShowSuccessModal } from "../redux/actions/adminActions";
import Loader from "../components/common/Loader";
// import { NavDropdown } from "react-bootstrap";
import { PostRequestAPI } from "../components/common/PostRequest";
import { toast } from "react-toastify";
import LogOutModal from "../components/common/Modal/LogOutModal";

const Home = () => {
  const { connect } = useContext(SocketConnect);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { productList, loader, accessToken,updateList } = useSelector(
    (state) => state.adminReducers
  );

  useEffect(() => {
    dispatch(
      setShowSuccessModal("")
    );
    dispatch(ViewProductDetails(""));
    dispatch(setLoader(true));
    const param = {
      transmit: "broadcast",
      type: App_url?.api?.getWebinarList,
      payload: { status: "twoDays" },
    };
    wsSend_request(connect, param);
    setTimeout(() => {
      dispatch(setLoader(false));
    }, 600);
  }, [connect, dispatch,updateList]);
  const logOutAPIHandler = async () =>{
    const payload = {
      token: accessToken,
    };
    const respose = await PostRequestAPI(App_url?.api?.logOut, payload);
    if (respose?.status === 200) {
      toast.success(respose?.data?.message);
      dispatch(getAccessToken());
      navigate(App_url?.link?.signIn);
    } else {
      dispatch(getAccessToken());
      navigate(App_url?.link?.signIn);
    }
    connect.close()
  }
  const LogoutFnct = async (e) => {
    e.preventDefault()
    dispatch(setShowSuccessModal({
      show:"Log_Out",
      callBackModal: (e)=>{logOutAPIHandler(e)}
    }))
   
  };
  return (
    <div className="pb-md-3">
      <LogOutModal />
      <div className="m-0 p-md-3 pb-5 pb-md-0 p-0  overflow-hidden">
        <div className="col-md-12 white-background pt-3 pt-md-3 pt-lg-0 p-0">
          <div className="col-md-12">
            <img
              src={App_url?.image?.bgHeader}
              alt=""
              width={"100%"}
              className="header-text-img"
            />
            <div
              className="d-flex aic jcsb manage-md-mt--2"
              style={{ marginTop: "-4rem" }}
            >
              <h5 className="fw-5  mt-md-0 mt-4 pt-2 pt-md-0 ms-4 ms-md-4 ps-md-0 z-100">
                Live Webinars
              </h5>
              {/* <NavDropdown className="btn btn-links txt-black fw-6 " id="basic-nav-dropdown">
                      <NavDropdown.Item onClick={() => navigate(App_url?.link?.history)} className="btn btn-links txt-black fw-6 d-flex aic gap-2"> <img src={App_url?.image?.FileText} alt="" /> History</NavDropdown.Item>
                      <NavDropdown.Item onClick={(e)=>{LogoutFnct(e)}} className="btn btn-links txt-black fw-6 d-flex aic gap-2"> <img src={App_url?.image?.logOut} alt="" /> Log Out</NavDropdown.Item>
                    </NavDropdown> */}
              <div className="d-flex aic gap-1 mt-4 mt-md-0">
                {" "}
                <div
                  onClick={(e) => {
                    navigate(App_url?.link?.history);
                  }}
                  className="btn btn-links z-100 txt-black fw-5 d-flex aic gap-2 fs-16px"
                >
                  {" "}
                  <img src={App_url?.image?.FileText} alt="" height={"24px"} width={"24px"} /> History
                </div>
                <div
                  onClick={(e) => {
                    LogoutFnct(e);
                  }}
                  className="btn btn-links z-100 txt-black fw-5 d-flex aic gap-2 fs-16px"
                >
                  {" "}
                  <img src={App_url?.image?.logOut} alt="" /> Log Out
                </div>
              </div>
            </div>
          </div>
          {loader ? (
            <div className="relative h-100px mt-5">
              {" "}
              <Loader />{" "}
            </div>
          ) : (
            <>
              <div className="col-md-12 mt-4 mt-md-4 p-0 ps-3">
                <h5 className="ps-2 pt-md-0 pt-3">Select Webinar</h5>
                <div className="row m-0">
                  <div className="col-md-12 row">
                    {productList?.data?.length > 0 &&
                      productList?.data?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="col-md-4 col-lg-3 col-12 pl-6px pr-6px py-1"
                          >
                            <ProductCard {...item} />
                          </div>
                        );
                      })}
                    {!productList?.data?.length && (
                      <div className="d-center my-5 fs-3">
                        Webinars Not Found
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
