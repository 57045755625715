import React,{ useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import {
  FloatingLabel,
  Form,
  FormSelect,
  InputGroup as ReactInputGroup,
} from "react-bootstrap";
import ReactSelect, {components} from "react-select";
import App_url from "./Constant";
import { uuidv4 } from "./Constant";
import Icon from "./Icon";
// const removeWhitespace = (value) => {
//   let formattedText = value;
//   formattedText = formattedText.replace(/\s/g, "");
//   return formattedText;
// };

const removeMultipleBlankSpace = (string, substring) => {
  string = string.replace(/\s+/g, " ");
  return string;
};

export const FormGroupControl = (props) => {
  if (props?.labelFloat) {
    return (
      <FloatingLabel
        label={props?.label}
        labelFloat={props?.labelFloat}
        className={props?.className}
        controlId={props?.controlId}
      >
        {props?.children}
      </FloatingLabel>
    );
  }
  return (
    <Form.Group className={props?.className} controlId={props?.controlId}>
      {props?.children}
    </Form.Group>
  );
};
export default function InputGroup(props) {
  const {
    className,
    label,
    Label,
    onChange,
    error,
    name,
    type,
    id,
    formClassName,
    labelClassName,
    errorClassName,
    placeholder,
    value,
    maxLength,
    minLength,
  } = props;
  let ids = useMemo(() => props?.id!=="" ? props?.id :uuidv4(), [props?.id]); // removed name dependency from here

  const callSelectedValue = () =>{
    if(props?.value){
      const FindData = props?.option?.find((item)=>item?.value === props?.value );
      if(FindData){
        return FindData;
      }else{
        return null;
      }
    }else{
      return null;
    }
  }
  const selectValue = useMemo(callSelectedValue, [props?.value,props?.option])
  // let FormGroupControl = Form.Group
  const data = {};
  if (props?.as) {
    data.as = props?.as;
  }
  const handleEmailChange = (value) => {
    let email = value;

    // Remove spaces and special characters
    email = email.replace(/\s/g, "");
    email = email.replace(/[^a-zA-Z0-9@.-]/g, "");

    // Convert to lowercase
    email = email.toLowerCase();

    // Restrict to one '@' character
    const atIndex = email.indexOf("@");
    if (atIndex !== -1) {
      const beforeAt = email.slice(0, atIndex + 1);
      const afterAt = email.slice(atIndex + 1).replace(/@/g, "");
      email = beforeAt + afterAt;
    }

    return email;
  };
  useEffect(()=>{
    if(props?.autoFocus){
      const FocusData = document.getElementById(ids);
      if(FocusData){
        FocusData.focus();
      }
    }
  },[props?.autoFocus,props?.id,ids])
  function removeSpaces(input) {
    let trimmedValue = input.trim();
    let cleanedValue = trimmedValue;

    if (trimmedValue.length > 2 && trimmedValue[0] === ' ' && trimmedValue[trimmedValue.length - 1] === ' ') {
      cleanedValue = trimmedValue.slice(1, -1);
    }
    if (input.charAt(input.length - 1) === ' ') {
      // If it does, update the state without the last character
      cleanedValue = input.slice(0, -1)
    }
    return cleanedValue;
  }
  function changeHandler(e) {
      if(props?.inputType !== "select"){
        const substring = "";
        if(e.target.value !== " "){
          e.target.value = removeMultipleBlankSpace(e.target.value, substring);
        }else{
          e.target.value = "";
        }
      }
      if (props?.number) {
        let { value } = e.target;
        value = value.replace(/^0+(?=\d)/, '');
        const dotCount = (value.match(/\./g) || []).length;
        if (dotCount > 1) {
          value = value.slice(0, value.lastIndexOf('.'));
        }
        value = value.replace(/\.+/g, '.');
        const regex = props?.digit ? /^\d*$/ : /^[0-9]*(\.[0-9]{0,2})?$/;
        const isValid = regex.test(value);
        if(e.target.value === ""){
          e.target.value = '';
        }else if (isValid ) {
          e.target.value = value;
        }else{
          e.target.value = props?.value;
        }
      }
      if (e.target.name === "email") {
        e.target.value = removeSpaces(e.target.value);
        e.target.value = handleEmailChange(e.target.value);
      }
      if(props?.validLength && e.target.value?.length>200){
        e.target.value = props?.value
      }
      onChange(e);
  }
  // function changeOtpHandler(e) {
  //   const data = {
  //     target:{
  //       name: props?.name,
  //       value: e,
  //     }
  //   }
  //   props?.onChange(data);
  // }
  function onSelect(e) {
    const data = {
      target:{
        name: props?.name,
        value: e?.value,
      }
    }
    props?.onChange(data, e);
  }
  const InputForm = () => {

    const getTranslation = (item) =>{
      return item?.title || item?.label ;
    }
    const SelectControl = () =>{
      const LeftIconView = () =>{
        return(
          <React.Fragment>
            {props?.leftLabel && (
              <ReactInputGroup.Text
                id={`left_icon_${id}`}
                className={` ${props?.leftIconClick ? "cursor-pointer" : ""}`}
                onClick={props?.leftIconClick}
              >
                {props?.leftLabel}
              </ReactInputGroup.Text>
            )}
          </React.Fragment>
        )
      }
      const Control = ({ children, ...props }) => {      
        return (
          <components.Control {...props}>
            <LeftIconView/>
            {children}
          </components.Control>
        );
      };
      const CustomOption = (props1) =>{
        const { children, ...props } = props1;
        return(
          <components.Option {...props}>
            {/* {props?.type === "input" && ( */}
              <span className={`circle ${props?.value !== "" && value === props1?.value?"selected select":""}`}>
                {props?.value !== "" && props?.value === props1?.value &&(
                  <Icon className='xsm white' attrIcon={App_url.image.Check}/>
                )}
              </span>
            {/* )} */}
            {children}
          </components.Option>
        )
      }

      if(props?.select){
        return(
          <ReactSelect
            classNamePrefix="custom-select"
            className="custom-select"
            components={{ Control, Option:CustomOption }}
            isSearchable
            name={props?.name}
            value={selectValue}
            options={props?.option}
            onChange={onSelect}
            placeholder={props?.placeholder}
        />
        )
      }
      return(
        <React.Fragment>
          {LeftIconView()}
        <FormSelect
            multiple={props?.multiple}
            name={name}
            id={id}
            className={className}
            value={props?.value}
            isInvalid={error ? true : false}
            onChange={onChange}
            disabled={props?.disabled}
          >
            {props?.option?.length === 0 ? (
              <option disabled={true} value={""}>
                {"No data found"}
              </option>
            ) : (
              props?.option?.map((item, index) => (
                <option
                  key={index?.toString()}
                  disabled={item?.disabled}
                  value={item?.value}
                >
                  {getTranslation(item)}
                </option>
              ))
            )}
          </FormSelect>
          </React.Fragment>
      )
    }
    if (props?.inputType === "select" || props?.type === "select") {
      return (
        <ReactInputGroup className={`${error ? "is-invalid" : ""}`}>
          {SelectControl()}
          {props?.rightLabel && (
            <ReactInputGroup.Text
              id={`left_icon_${id}`}
              className={` ${props?.rightIconClick ? "cursor-pointer" : ""}`}
              onClick={props?.rightIconClick}
            >
              {props?.rightLabel}
            </ReactInputGroup.Text>
          )}
        </ReactInputGroup>
      );
    }
    const FormControl = () => {
     
      return (
        <Form.Control
          // id={id}
          type={props?.number?"text":type}
          onWheel={(e) => e.target.blur()}
          placeholder={placeholder}
          title={placeholder}
          name={name}
          onChange={changeHandler}
          className={` ${props?.rightLabel?"right-input":""}  ${props?.leftLabel?"left-input":""} ${className}`}
          onClick={props?.onClick}
          {...data}
          // onKeyDown={(e) => EmptySpaceFieldValid(e, props, changeHandler)}
          value={props?.value}
          isInvalid={error ? true : false}
          disabled={props?.disabled}
          autoFocus={props?.autoFocus}
          maxLength={maxLength}
          minLength={minLength}
        />
      );
    };
    if (props?.leftLabel || props?.rightLabel) {
      return (
        <ReactInputGroup className={error ? "is-invalid" : ""} >
          {props?.leftLabel && (
            <ReactInputGroup.Text
              id={`left_icon_${id}`}
              className={`left_text ${props?.leftIconClick ? "cursor-pointer" : ""}`}
              onClick={props?.leftIconClick}
            >
              {props?.leftLabel}
            </ReactInputGroup.Text>
          )}
          {FormControl()}
          {props?.rightLabel && (
            <ReactInputGroup.Text
              id={`left_icon_${id}`}
              className={`right_text ${props?.rightIconClick ? "cursor-pointer" : ""}`}
              onClick={props?.rightIconClick}
            >
              {props?.rightLabel}
            </ReactInputGroup.Text>
          )}
        </ReactInputGroup>
      );
    }
    return FormControl();
  };
  const LabelForm = () => {
    return (label || Label) && !props?.labelFloat ? (
      <React.Fragment>
        <Form.Label className={`${labelClassName}`}>
          {Label}
          {label} {props?.required && <span className="form-required">*</span>}
        </Form.Label>
      </React.Fragment>
    ) : (
      <React.Fragment></React.Fragment>
    );
  };
  const ErrorForm = () => {
    return error ? (
      <React.Fragment>
        <Form.Control.Feedback type="invalid" className={`${errorClassName}`}>
          {error}
        </Form.Control.Feedback>
      </React.Fragment>
    ) : (
      <React.Fragment></React.Fragment>
    );
  };
  const HightLightForm = () => {
    return props.highlight ? (
      <React.Fragment>
        <span  className={`highlight`}>
          {props.highlight}
        </span>
      </React.Fragment>
    ) : (
      <React.Fragment></React.Fragment>
    );
  };
  
  return (
    <FormGroupControl
      label={label}
      labelFloat={props?.labelFloat}
      className={`form_group ${props?.otp?"form-group-otp":""} ${
        props?.leftIcon ||
        props?.rightIcon ||
        props?.rightLabel ||
        props?.leftLabel ||
        props?.inputType === "select" ||
        props?.type === "select"
          ? "input_group_icon"
          : "normal-control"
      } mt-0 form_style_${props?.formStyle} form-size-${props?.size} ${props?.inline?"d-inline-block":""} radius-${
        props?.radius
      } ${formClassName} ${error ? "form-validate" : ""} form_variate ${(props?.type === "time" || props?.type === "date") && "date_time_icon"}`}
      controlId={ids}
    >
      {LabelForm()}
      {InputForm()}
      {ErrorForm()}
      {HightLightForm()}
    </FormGroupControl>
  );
}
InputGroup.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength:PropTypes.any,
  minLength:PropTypes.any,
  title: PropTypes.string,
  label: PropTypes.any,
  Label: PropTypes.any,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  error: PropTypes.any,
  name: PropTypes.string,
  highlight: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  formClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  as: PropTypes.any,
  radius: PropTypes.number,
  size: PropTypes.any,
  number: PropTypes?.bool,
  labelFloat: PropTypes?.bool,
  value: PropTypes?.any,
  formStyle: PropTypes.any,
  required: PropTypes?.bool,
  inputType: PropTypes?.string,
  option: PropTypes?.array,
  leftIcon: PropTypes?.any,
  rightIcon: PropTypes?.any,
  leftLabel: PropTypes?.any,
  rightLabel: PropTypes?.any,
  onKeyDown: PropTypes?.func,
  country_isocode: PropTypes?.any,
  leftIconClass: PropTypes?.any,
  rightIconClass: PropTypes?.any,
  onEditorChange: PropTypes.func,
  rightIconClick: PropTypes.func,
  leftIconClick: PropTypes.func,
  multiple: PropTypes?.bool,
  inline: PropTypes?.bool,
  disabled: PropTypes?.bool,
  validLength: PropTypes?.bool,
  digit: PropTypes?.bool,
  otp: PropTypes?.bool,
  autoFocus: PropTypes?.bool,
};
InputGroup.defaultProps = {
  className: "",
  label: "",
  placeholder: "",
  minLength:"",
  maxLength:"",
  title: "",
  onChange: () => {},
  onClick: () => {},
  onEditorChange: () => {},
  error: "",
  name: "name",
  highlight:"",
  type: "text",
  id: "",
  formClassName: "",
  labelClassName: "",
  errorClassName: "",
  as: "",
  radius: 1,
  size: 2,
  number: false,
  labelFloat: false,
  value: "",
  formStyle: "normal",
  disabled: false,
  required: false,
  inputType: "input",
  option: [],
  leftIcon: "",
  rightIcon: "",
  leftIconClass: "",
  rightIconClass: "",
  country_isocode: "",
  leftLabel: "",
  rightLabel: "",
  onKeyDown: ()=>{},
  multiple: false,
  inline:false,
  digit: false,
  otp: false,
  autoFocus: false,
  Label:null,
  validLength: false,
  rows:"",
};
