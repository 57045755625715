/* eslint-disable */
import { ActionTypes } from "../actions/adminActions";

const initailData = {
  is_login: "no",
  user_id: "",
  device_id: "",
  selfInfo: {},
  accessToken: "",
  successModalData: "",
  confirmModal: "",
  productList: "",
  productDetail: "",
  historyList: "",
  loader:false,
  updateList:""
};

export const adminReducers = (state = initailData, action) => {
  switch (action.type) {
    case ActionTypes.SUCCESS_MODAL_DATA:
      return {
        ...state,
        successModalData: {
          show: action?.payload?.show,
          data: action?.payload?.data,
        },
      };

    case ActionTypes.CONFIRM_MODAL:
      return {
        ...state,
        confirmModal: {
          ...action?.payload,
        },
      };
    case ActionTypes.GET_HISTORY_LIST:
      return {
        ...state,
        historyList: {
          ...action?.payload,
        },
      };
    case ActionTypes.GET_PRODUCT_LIST:
      return {
        ...state,
        productList: {
          ...action?.payload,
        },
      };
    case ActionTypes.VIEW_PRODUCT_DETAILS:   
      return {
        ...state,
        productDetail: {
          ...action?.payload,
        },
      };
      case ActionTypes.REFRESH_BOOKING:
        if (state?.productDetail?.data?.id === action?.payload?.data?.id){
          return{ ...state,
           productDetail:{...action.payload}
          }
         }else{
          return {...state}
         }
    case ActionTypes.IS_LOGIN:
      return {
        ...state,
        is_login: action.payload,
      };
    case ActionTypes.GET_USER_ID:
      return {
        ...state,
        user_id: action.payload,
      };
    case ActionTypes.GET_DEVICE_ID:
      return {
        ...state,
        device_id: action.payload,
      };
    case ActionTypes.SELF_DETAILS:
      return {
        ...state,
        selfInfo: action.payload,
      };
      case ActionTypes.SET_LOADER:
        return {
          ...state,
          loader: action.payload,
        };
        case ActionTypes.UPDATE_LIST_DYNAMIC:
          return {
            ...state,
            updateList: action.payload,
          };
    case ActionTypes.CLEAR_FORM_SET_FUCT:
      return {
        ...state,
        ClearFormSet: {
          url: action?.payload?.url,
          action: action?.payload?.action,
        },
      };
    case ActionTypes.EDIT_SELF_DETAILS:
      return {
        ...state,
        selfInfo: action.payload,
      };
    case ActionTypes.GET_ACCESSTOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case ActionTypes.LOADER_MAIN_START:
      return {
        ...state,
        loaderMain: action.payload,
      };

    default:
      return state;
  }
};
