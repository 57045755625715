import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setShowSuccessModal } from "../../../redux/actions/adminActions";
import App_url from "../Constant";

export default function SignUpSuccess() {
  const dispatch = useDispatch();
  const { successModalData } = useSelector((state) => state.adminReducers);
  const CloseData = async () => {
    if (successModalData?.callBack) {
      await successModalData?.callBack();
    }
    dispatch(
      setShowSuccessModal({
        show: "",
        data: "",
      })
    );
  };

  return (
    <Modal
      show={successModalData?.show === "SIGNINSUCCESS"}
      centered
      onHide={CloseData}
    >
      <Modal.Body className="modal-body d-center flex-column gap-2">
        <img src={App_url?.image?.checkIcon} alt="" />
        <h5 className="fw-600">Thank You</h5>
        <h6 className="txt-secondary text-center w-75">
          Please stand by for approval
        </h6>
        <button className="yellow-bg fw-500 btn w-50" onClick={CloseData}>
          OK
        </button>
      </Modal.Body>
    </Modal>
  );
}
SignUpSuccess.propTypes = {
  handleClose: PropTypes?.func,
  show: PropTypes?.bool,
};
SignUpSuccess.defaultProps = {
  handleClose: () => {},
  show: false,
};
