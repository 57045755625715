import React, { useContext, useEffect } from 'react'
import App_url from "../components/common/Constant";
import ProductCard from '../components/common/ProductCard';
import { useNavigate } from 'react-router-dom';
import { SocketConnect } from '../context/context';
import wsSend_request from '../components/common/WsSendRequest';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from '../redux/actions/adminActions';
import Loader from '../components/common/Loader';

const History = () => {
  const {connect} = useContext(SocketConnect);
  const navigate = useNavigate()
const dispatch = useDispatch()
  const { productList,loader,updateList } = useSelector((state) => state.adminReducers);
  useEffect(()=>{
    dispatch(setLoader(true))
    const param = { "transmit": "broadcast", "type": App_url?.api?.getWebinarList, "payload" : {"status":"thirtyDays"} };
    wsSend_request(connect, param);
    setTimeout(() => {
      dispatch(setLoader(false));
      }, 600);
  },[connect,dispatch,updateList])

  return (
    <div className=''>
      <div className="m-0 p-md-3 pb-5 pb-md-0 p-0 overflow-hidden">
        <div className="col-md-12 white-background pt-3 pt-md-3 pt-lg-0 p-0">
        <div className="col-md-12">
          <img src={App_url?.image?.bgHeader} alt="header" loading='lazy' width={"100%"} className='header-text-img' />
          <div className="d-flex aic jcsb manage-md-mt--2"  style={{marginTop:"-4rem"}} >
         <div className='d-flex aic mt-md-0 mt-5 ms-md-1 ps-md-0 z-100'>

         <img
                src={App_url?.image?.CaretLeft}
                alt="left arrow"
                loading='lazy'
                className="mb-2 pointer z-100"
                onClick={() => {
                  navigate(App_url?.link?.home);
                }}
              />
          <h5 className='fw-5 '>
         
            Webinars History</h5>
         </div>

          </div>
        </div>
        {loader ? <div className='relative h-100px mt-5'> <Loader/> </div> : (<>

        <div className="col-md-12 mt-4 p-0 ps-3">
          <h5 className='ps-2 pt-3'>Select Webinar to Result</h5>
          <div className="row m-0">
          <div className="col-md-12 row ">
            {productList?.data?.length > 0 && productList?.data?.map((item,index)=>{
              return <div key={index} className='col-md-4 col-lg-3 col-12 pl-6px pr-6px py-1'>
                <ProductCard {...item} />
              </div>
            })}
                  {!productList?.data?.length && <div className='d-center my-5 fs-3'>
              Webinars Not Found
              </div>}
          </div>
          </div>
        </div></>)}
        </div>
      </div>
    </div>
  )
}

export default History