export const ActionTypes = {
  IS_LOGIN: "IS_LOGIN",
  GET_USER_ID: "GET_USER_ID",
  GET_DEVICE_ID: "GET_DEVICE_ID",
  SELF_DETAILS: "SELF_DETAILS",
  EDIT_SELF_DETAILS: "EDIT_SELF_DETAILS",
  GET_ACCESSTOKEN: "GET_ACCESSTOKEN",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",
  GET_PERMISSION_LIST: "GET_PERMISSION_LIST",
  CONFIRM_MODAL:"CONFIRM_MODAL",
  SUCCESS_MODAL_DATA:"SUCCESS_MODAL_DATA",
  GET_PRODUCT_LIST:"GET_PRODUCT_LIST",
  VIEW_PRODUCT_DETAILS:"VIEW_PRODUCT_DETAILS",
  GET_HISTORY_LIST:"GET_HISTORY_LIST",
  SET_LOADER:"SET_LOADER",
  UPDATE_LIST_DYNAMIC:"UPDATE_LIST_DYNAMIC",
  REFRESH_BOOKING:"REFRESH_BOOKING",
};
export const setRefreshBooking = (user) => {
  return {
    type: ActionTypes.REFRESH_BOOKING,
    payload: user,
  };
};
export const setUpdateList = (user) => {
  return {
    type: ActionTypes.UPDATE_LIST_DYNAMIC,
    payload: user,
  };
};
export const setLoader = (user) => {
  return {
    type: ActionTypes.SET_LOADER,
    payload: user,
  };
};
export const editSelfDetails = (user) => {
  return {
    type: ActionTypes.EDIT_SELF_DETAILS,
    payload: user,
  };
};
export const getSelfDetails = (user) => {
  return {
    type: ActionTypes.SELF_DETAILS,
    payload: user,
  };
};
export const getAccessToken = (user) => {
  return {
    type: ActionTypes.GET_ACCESSTOKEN,
    payload: user,
  };
};
export const setShowSuccessModal = (payload, data,msg) => {
  const updatedPayload = {
    ...payload,
    show: payload ? payload.show : false, // Assuming default value of 'show' is false
    data: data ? data : payload, // If 'data' is provided, use it, otherwise use the original payload
    msg: msg, 
  };

  return {
    type: ActionTypes.SUCCESS_MODAL_DATA,
    payload: updatedPayload,
  };
};
export const getHistoryList = (product) => {
  return {
    type: ActionTypes.GET_HISTORY_LIST,
    payload: product,
  };
};
export const getProductList = (product) => {
  return {
    type: ActionTypes.GET_PRODUCT_LIST,
    payload: product,
  };
};
export const ViewProductDetails = (project) => {
  return {
    type: ActionTypes.VIEW_PRODUCT_DETAILS,
    payload: project,
  };
};
export const checkLogin = (status) => {
  return {
    type: ActionTypes.IS_LOGIN,
    payload: status,
  };
};

export const getUserId = (id) => {
  return {
    type: ActionTypes.GET_USER_ID,
    payload: id,
  };
};

export const getDeviceId = (id) => {
  return {
    type: ActionTypes.GET_DEVICE_ID,
    payload: id,
  };
};

