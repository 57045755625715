import React from 'react'
import { useNavigate } from 'react-router-dom'
import App_url, { truncateString } from './Constant'

const ProductCard = (props) => {
    const navigate = useNavigate()
    const handleRoute = () => {
      if(window.location.href.includes("home")){
      navigate(`${App_url?.link?.detail}/${props?.id}`)
      }else{
      navigate(`${App_url?.link?.historyDetail}/${props?.id}`)
      }
    }
  return (
    <div className='relative pointer' onClick={handleRoute}>
        <img src={`${App_url?.BACKEND_URL}uploads/${props?.webinar_image}`} className='br-12px' alt="product" loading="lazy" width={"100%"} style={{height:"200px",objectFit:"cover"}}/>
            <div className="absolute start-0 top-0 h-100 w-100 pt-2 ps-2">
                <div className="d-flex jcsb aic pe-2">
                <h6 className='text-white fw-600 text-truncate'>{truncateString(props?.webinar_title,window?.screen.width > 620 ? 25 : 18)}</h6>
                <h6 className='rounded-pill fs-14px bg-white px-3 py-2 mt-0 mt-md-0 text-black fw-500 pointer'>{(props?.winner_number || props?.available_seats ===0 )? "Completed" : "In Progress"}</h6>
                </div>
                <div className="d-flex flex-column absolute bottom-0">
                    <h6 className='fs-16px fw-500 text-white'>{props?.ticket_size}</h6>
                    <h6 className='fs-16px fw-500 text-white'>Total Seat</h6>
                    <h6 className='fs-16px fw-500 text-white'>{props?.available_seats}</h6>
                    <h6 className='fs-16px fw-500 text-white'>Available Seat</h6>
                    <h5 className='fw-500 text-yellow'>$ {props?.ticket_price}</h5>
                </div>
            </div>
    </div>
  )
}

export default ProductCard